@import "../../scss/mixins";

.ReactModal__Body--open {
  .sticky-step-1-bar {
    display: none;
  }
}

.ReactModalPortal {
  position: relative;
  z-index: 300;

  @include laptop-down {
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #e2e2e2;
    }

    ::-webkit-scrollbar-thumb {
      background: #cccccc;
    }
  }
}

.basket-help-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(#fff, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.basket-help-modal {
  position: absolute;
  background-color: white;
  border-radius: 16px;
  padding: 32px;
  z-index: 9999999999999999999999999;
  margin: 10px;
  max-height: 100vh;
  overflow: scroll;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  @include tablet {
    max-width: 600px;
  }

  @include desktop {
    max-width: 700px;
  }

  @include mobileLandscape {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    > .delivery-modal-okay {
      display: none !important;
    }

    div .delivery-modal-okay {
      display: block !important;
    }
  }

  @include tablet {
    display: block;

    > .delivery-modal-okay {
      display: block !important;
    }

    div .delivery-modal-okay {
      display: none !important;
    }
  }

  div .delivery-modal-okay {
    display: none;
  }

  .delivery-modal-okay {
    background-color: var(--color-mint-green);
    padding: 10px 20px;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    display: block;
    width: fit-content;
    margin: auto;
    margin-top: 16px;
    cursor: pointer;

    @include tablet {
      padding: 12px 50px;
    }
  }

  h2 {
    color: var(--color-raspberry-sorbet);
    margin-bottom: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 21px;

    @include mobileLandscape {
      font-size: 15px;
    }

    @include tablet {
      font-size: 28px;
    }

    @include desktop {
      font-size: 36px;
    }
  }

  h3 {
    color: var(--color-raspberry-sorbet);
    margin-bottom: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 17px;

    @include mobileLandscape {
      font-size: 15px;
    }

    @include tablet {
      font-size: 24px;
    }

    @include desktop {
      font-size: 28px;
    }
  }

  p {
    margin-bottom: 22px;
    color: var(--color-black);
    font-weight: 300;
    font-size: 14px;

    @include mobileLandscape {
      font-size: 12px;
    }

    @include tablet {
      font-size: 16px;
    }

    @include desktop {
      font-size: 25px;
    }

    &.text-main {
      font-size: 21px;

      @include mobileLandscape {
        font-size: 12px;
      }

      @include tablet {
        font-size: 20px;
      }

      @include desktop {
        font-size: 30px;
      }
    }
  }
}

.sticky-step-1-bar {
  padding: 10px;
  background-color: transparent;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  box-sizing: border-box;
  height: 60px;
  margin-bottom: 0px;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.5);

  @include mobile {
    justify-content: space-between;
    align-items: center;
  }

  @include mobileLandscape {
    justify-content: space-between;
    align-items: center;
  }

  @include tablet {
    justify-content: space-between;
    align-items: center;
  }
  @include laptop {
    box-shadow: none;
    justify-content: flex-end;
    z-index: unset;
  }
  @include desktop {
    justify-content: flex-end;
  }

  .next {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-raspberry-sorbet);
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    height: 61px;
    width: 120px;
    cursor: pointer;
    @include mobile {
      height: 46px;
    }

    @include laptop {
      background-color: var(--color-mint-green);
      height: 61px;
      min-width: 155px;
    }

    @include desktop {
      height: 61px;
      min-width: 180px;
    }

    &.create-account {
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .back {
    background-image: url("../../assets/back-btn.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 55px;
    // color: #ff005e;
    // color: var(--color-raspberry-sorbet);
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0;
    line-height: normal;
    padding: 8px 8px 8px 0;
    width: 75px;
    height: 42px;
    box-sizing: border-box;
    // &:before {
    //   content: "";
    //   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 224.008 224.008' style='enable-background:new 0 0 224.008 224.008;fill:%23FF005E;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M216.075,197.059l-171-85.5l171-84.9c13.1-6.7,8.4-26.6-6.3-26.6v0c-2.1,0-4.7,0.5-6.3,1.6l-195.6,98 c-10.5,4.7-10.5,19.4,0,24.6l195.6,98C220.275,230.659,232.875,205.459,216.075,197.059z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    //   height: 10px;
    //   width: 10px;
    //   display: inline-block;
    //   margin-right: 5px;
    //   margin-top: 2px;
    // }
    @include laptop {
      display: none;
    }
    @include desktop {
      display: none;
    }
  }

  @include mobile {
    background-color: rgba($color: #fff, $alpha: 1);
    height: 61px;
  }

  @include mobileLandscape {
    background-color: rgba($color: #fff, $alpha: 1);
    height: 61px;
  }

  @include tablet {
    background-color: rgba($color: #fff, $alpha: 1);
    height: 61px;
  }

  @include laptop {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 780px;
    height: 100%;
    margin-bottom: 50px;
  }

  @include desktop {
    margin: auto;
    margin-bottom: 100px;
  }
}

.basket {
  display: grid;

  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;

  @include mobile {
    margin-left: 0px;
    margin-right: 0px;
  }

  @include mobileLandscape {
    margin-left: 0px;
    margin-right: 0px;
  }

  @include tablet {
    margin-left: 70px;
    margin-right: 70px;
  }

  @include laptop {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 75px;
  }

  @include desktop {
    margin-top: 100px;
    margin-left: unset;
    margin-right: unset;
  }

  &-header {
    position: relative;
  }

  h1 {
    text-align: center;
    margin-bottom: 14px;
    color: var(--color-raspberry-sorbet);
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 17px;

    &.title-back-button {
      display: flex;
      flex-direction: row;

      span {
        margin: 0 auto;
        font-size: 23px;
        line-height: 29px;
        letter-spacing: -0.01em;
        text-align: center;
        @include laptop {
          font-size: 30px;
          line-height: 39px;
        }
        @include desktop {
          font-size: 36px;
          line-height: 45px;
        }
      }

      .back {
        align-self: flex-start;
        justify-self: flex-start;
        height: 61px;
        width: 155px;
        background: #ffffff;
        color: #8ddc73;
        font-size: 18px;
        font-weight: bold;
        border: 3px solid #8ddc73;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -20px;

        &:before {
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 224.008 224.008' style='enable-background:new 0 0 224.008 224.008;fill:%238DDC73;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M216.075,197.059l-171-85.5l171-84.9c13.1-6.7,8.4-26.6-6.3-26.6v0c-2.1,0-4.7,0.5-6.3,1.6l-195.6,98 c-10.5,4.7-10.5,19.4,0,24.6l195.6,98C220.275,230.659,232.875,205.459,216.075,197.059z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          height: 10px;
          width: 10px;
          display: inline-block;
          margin-right: 5px;
          margin-top: 2px;
        }

        @include mobile {
          display: none;
        }

        @include mobileLandscape {
          display: none;
        }

        @include tablet {
          display: none;
        }

        @include laptop {
          display: flex;
          font-size: 16px;
          width: 100px;
          height: 40px;
          top: auto;
          left: 80px;
        }

        @include desktop {
          display: flex;
          font-size: 18px;
          height: 61px;
          width: 155px;
          top: -20px;
          left: 0;
        }
      }
    }

    @include laptop {
      margin-bottom: 25px;
    }

    @include desktop {
      margin-bottom: 30px;
    }
  }

  .items {
    .error-centered-text {
      display: flex;
      justify-content: center;
      span {
        color: var(--color-raspberry-sorbet);
      }
    }
    .item {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      background-color: #ffffff;
      padding: 10px;

      .slot-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .slot {
          position: relative;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 6px;

          @include laptop {
            gap: 0;
          }

          & + .slot {
            margin-top: 10px;

            @include laptop {
              margin-top: 35px;

              .delivery-note {
                top: -25px;
              }
            }
          }

          .select__container {
            order: 0;
            flex: 0 0 calc(50% - 3px);
            max-width: calc(50% - 3px);

            @include laptop {
              margin-bottom: 10px;
              order: 0;
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
      }

      .delivery-note {
        text-align: center;
        margin-bottom: 5px;
        height: 18px;
        width: 100%;
        flex: 0 0 100%;

        @include laptop {
          flex: unset;
          width: 250px;
          position: absolute;
          top: -32px;
          right: 0;
          margin-bottom: 0;
        }

        &::before {
          background-color: var(--color-raspberry-sorbet);
          position: absolute;
          content: "";
          width: 100%;
          height: 1px;
          top: 10px;
          left: 0;
        }

        span {
          color: var(--color-raspberry-sorbet);
          font-size: 14px;
          font-weight: bold;
          line-height: 17px;
          display: inline-block;
          background: #fff;
          position: relative;
          z-index: 2;
          padding: 0 5px;
        }
      }

      .shop-name {
        color: var(--color-mid-charcoal);
        font-size: 14px;
        line-height: 17px;
        margin-top: 2px;
        margin-bottom: 8px;
      }

      .item-left-container {
        display: flex;
        width: 100%;

        @include laptop {
          flex: 0 0 calc(100% - 250px);
          max-width: calc(100% - 250px);
          width: unset;
        }
      }

      .item-right-container {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 15px;
        justify-content: space-between;

        @include laptop {
          flex-direction: column;
          align-items: unset;
          justify-content: unset;
          flex: 0 0 250px;
          max-width: 250px;
          margin-top: 0;
        }

        .select__container {
          order: 1;
          flex: 0 0 200px;
          max-width: 200px;

          @include laptop {
            order: 0;
            flex: 0 1 auto;
            max-width: unset;
          }

          .mySelectDefault {
            margin-right: 0;
            max-width: 100%;

            .fieldset {
              margin-top: 0;
              box-sizing: border-box;
              border: 2px solid var(--color-mid-charcoal);
              padding: 0;
              box-sizing: border-box;
              font-size: 18px;

              .selectedDisplay {
                color: var(--color-mid-charcoal);
                font-weight: 700;
              }

              .select_option {
                @include mobile {
                  padding: 0.8em 0.5em;
                }
                @include tablet {
                  padding: 0.5em 0 0.5em 0.5em;
                }
              }
            }

            .mainSectionWrapper {
              min-height: 40px;
              cursor: pointer;

              .cancelContainer {
                display: none;
              }

              .downArrowContainer {
                .downArrowContent {
                  & > svg {
                    fill: var(--color-mid-charcoal);
                    border: 2px solid var(--color-mid-charcoal);
                    border-radius: 50%;
                    box-sizing: border-box;
                  }
                }
              }
            }
          }
        }

        .will-deliver {
          color: var(--color-mid-charcoal);
          font-size: 14px;
          line-height: 17px;
          margin-top: 5px;

          @include laptop {
            margin-top: 0;
          }
        }
      }

      @include laptop {
        max-width: 780px;
        margin: auto;
        margin-bottom: 25px;
        padding: 10px;
      }

      .img-wrapper {
        flex: 0 0 130px;
        max-width: 130px;
        height: 152px;
        position: relative;
        margin-right: 10px;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          background-color: var(--color-raspberry-sorbet);
        }

        @include laptop {
          flex: 0 0 245px;
          max-width: 245px;
          margin-right: 30px;
        }
      }

      .item-text {
        color: var(--color-mid-charcoal);
        flex: 1 1 auto;

        @include laptop {
          margin-right: 30px;
        }

        .box-name {
          font-size: 17px;
          line-height: 21px;
          font-weight: bold;
          margin-bottom: 6px;
        }

        .get-it {
          color: var(--color-cornflower-blue);
          font-size: 12px;
          font-style: italic;
          line-height: 14px;
          margin-bottom: 14px;

          @include laptop {
            margin-bottom: 10px;
          }
        }

        .size-and-price {
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 15px;
        }

        .customise-box {
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          margin-top: auto;
          margin-bottom: 6px;

          @include laptop {
            margin-top: 0;
            margin-bottom: 0;
          }

          p {
            color: var(--color-mint-green);
            cursor: pointer;
            text-decoration: underline;
            font-weight: 500;
          }
        }

        button {
          color: var(--color-raspberry-sorbet);
          font-size: 19px;
          margin: 0;
          outline: none;
          background-color: transparent;
          border: none;
          cursor: pointer;
          position: absolute;
          right: 0;
        }

        @include mobile {
          display: flex;
          flex-direction: column;
        }
      }

      .quick__delivery-btn {
        background-color: #9cb6ff;
        padding: 9px 2px;
        border-radius: 0px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        p,
        svg {
          color: #fff !important;
        }

        svg {
          height: 24.0625px;
          width: 24px;
        }
        div {
          display: flex;
          flex-direction: column;
          @include laptop {
            flex-direction: row;
          }

          > p:first-of-type {
            font-weight: 700;
          }
        }
        p {
          margin-left: 10px;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: 17px;
          letter-spacing: -0.03em;
        }

        // @include mobile {
        //   width: 193px;
        // }
        @include tablet {
          width: 218px;
        }
        @include laptop {
          padding: 15px 5px;
          width: 100%;
        }
      }

      .select-quantity,
      .select-frequency {
        width: 100%;

        display: flex;
        gap: 5px;

        @include laptop {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        .react-select {
          width: 100%;

          &__control {
            border: 2px solid var(--color-mid-charcoal);
            border-radius: 0;
            box-shadow: none;
            min-height: 44px;
            cursor: pointer;

            &--is-focused {
              border: 2px solid var(--color-mid-charcoal);
              box-shadow: none;
            }
          }

          &__placeholder {
            @include mobileLandscape-down {
              font-size: 16px;
            }
          }

          &__value-container {
            font-size: 18px;
            font-weight: 700;
          }

          &__single-value {
            color: var(--color-mid-charcoal);
          }

          &__indicator {
            color: var(--color-mid-charcoal);

            &-separator {
              display: none;
            }

            svg {
              border: 2px solid var(--color-mid-charcoal);
              border-radius: 100%;
              box-sizing: border-box;
            }
          }

          &__menu {
            margin-top: 0;
            border: 1px solid #cccccc;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
              0 6px 20px 0 rgba(0, 0, 0, 0.19);
            border-radius: 0 0 0.5em 0.5em;
            overflow: hidden;

            &-list {
              padding: 0;
            }
          }

          &__option {
            background-color: #ffffff;
            font-size: 18px;
            color: #000000;
            padding: 0.8em 0.5em;

            @include tablet {
              padding: 0.5em 0 0.5em 0.5em;
            }

            &--is-focused {
              background-color: #eeeeee;
            }

            &--is-selected {
              background-color: #dddddd;
            }
          }
        }

        button {
          width: 100%;
          height: 100%;
          padding: 5px 10px 5px 10px;

          @include laptop {
            padding: 5px;
          }
        }

        select {
          width: 100%;
          height: 42px;
          color: var(--color-mid-charcoal);
          background-color: #ffffff;
          border-color: var(--color-mid-charcoal);
          font-size: 18px;
          font-weight: 700;
          padding-left: 10px;

          @include laptop {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.delivery-schedule {
  display: grid;
  grid-template-columns: 1fr;
  gap: 34px;
  padding: 10px;

  justify-self: center;
  align-self: center;
  max-width: 80%;

  @include mobile {
    padding: 0;
  }

  @include mobileLandscape {
    padding: 0;
  }

  @include tablet {
    padding: 10px;
  }

  @include laptop {
    padding: 10px;
  }

  @include desktop {
    padding: 10px;
  }

  .item {
    display: grid;
    grid-template-columns: 2fr min-content;
    padding: 27px;
    gap: 2em;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    max-width: 780px;
    margin: 0 auto;
    min-width: 100%;

    @include laptop {
      grid-template-columns: 2fr min-content 1fr;
    }

    .item-trash {
      display: grid;
      justify-content: end;
      align-items: flex-end;
    }

    .item-desc {
      flex: 0 0 50%;
      display: flex;
      flex-direction: row;
      gap: 22px;
      padding-top: 10px;

      @include mobile {
        margin-bottom: 10px;
        flex: 0 0 100%;
        padding-top: 0;
      }

      @include mobileLandscape {
        margin-bottom: 10px;
        flex: 0 0 100%;
        padding-top: 0;
      }

      @include tablet {
        margin-bottom: 10px;
        flex: 0 0 100%;
        padding-top: 0;
      }

      @include laptop {
        margin-bottom: unset;
        flex: 0 0 50%;
      }

      @include desktop {
        margin-bottom: unset;
        flex: 0 0 50%;
      }

      img {
        width: 100%;
        object-fit: cover;
        @include mobile {
          width: 130px;
          height: 152px;
        }
        // @include mobileLandscape {
        //   width: 210px;
        //   height: 90px;
        // }
        // @include tablet {
        //   width: 210px;
        //   height: 90px;
        // }
        // @include laptop {
        //   width: 89px;
        //   height: 90px;
        // }
        @include desktop {
          width: 246px;
          height: 152px;
        }
      }

      .text {
        .name {
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 25px;
          letter-spacing: -0.01em;
          text-align: left;
          color: var(--color-mid-charcoal);
        }

        .desc {
          font-size: 16px;
          font-weight: 300;
          color: var(--color-black);
          margin-top: 10px;
        }

        .vendor {
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 20px;
          letter-spacing: 0.03em;
          text-align: left;
          color: var(--color-mid-charcoal);
        }
      }
    }

    .item-slots {
      flex: 0 0 50%;

      @include mobile {
        flex: 0 0 100%;
      }

      @include mobileLandscape {
        flex: 0 0 100%;
      }

      @include tablet {
        flex: 0 0 100%;
      }
      @include laptop {
        flex: 0 0 50%;
      }
      @include desktop {
        flex: 0 0 50%;
      }
      p {
        margin: 0 0 14px 0;
      }
      .slot {
        .divider {
          display: grid;
          grid-template-columns: auto min-content auto;
          margin: 5px 0;

          hr {
            width: 90%;
            background-color: var(--color-raspberry-sorbet);
            border-color: var(--color-raspberry-sorbet);
            border-style: solid;
          }

          p {
            text-align: center;
            font-weight: 700;
            font-size: 16px;
            color: var(--color-raspberry-sorbet);
            margin-bottom: 4px;
            white-space: nowrap;
          }
        }

        .settings {
          display: flex;
          flex-direction: column;
          gap: 6px;

          select {
            width: 100%;
            height: 42px;
            color: var(--color-mid-charcoal);
            background-color: #ffffff;
            border-color: var(--color-mid-charcoal);
            font-size: 18px;
            font-weight: 700;
            padding-left: 10px;

            &.week {
              grid-column: 1/3;
            }
          }
        }
      }
    }
  }
}

.basket-3 {
  margin-top: 20px;
  @include laptop {
    margin-top: 75px;
  }
  @include desktop {
    margin-top: 100px;
  }
  .basket-header {
    position: relative;
  }
  .login-or-register {
    form {
      @include tablet {
        max-width: 431px;
      }
      @include laptop {
        max-width: 530px;
      }
    }
  }
  .sticky-step-1-bar {
    @include laptop {
      max-width: 530px;
    }
    @include desktop {
      max-width: 530px;
    }
  }

  h1 {
    text-align: center;
    margin-bottom: 14px;
    color: var(--color-raspberry-sorbet);
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 17px;

    &.title-back-button {
      display: flex;
      flex-direction: row;

      span {
        margin: 0 auto;
        font-size: 23px;
        line-height: 29px;
        letter-spacing: -0.01em;
        text-align: center;
        @include laptop {
          font-size: 30px;
          line-height: 39px;
        }
        @include desktop {
          font-size: 36px;
          line-height: 45px;
        }
      }

      .back {
        align-self: flex-start;
        justify-self: flex-start;
        height: 61px;
        width: 155px;
        background: #ffffff;
        color: #8ddc73;
        font-size: 18px;
        font-weight: bold;
        border: 3px solid #8ddc73;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -20px;

        &:before {
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 224.008 224.008' style='enable-background:new 0 0 224.008 224.008;fill:%238DDC73;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M216.075,197.059l-171-85.5l171-84.9c13.1-6.7,8.4-26.6-6.3-26.6v0c-2.1,0-4.7,0.5-6.3,1.6l-195.6,98 c-10.5,4.7-10.5,19.4,0,24.6l195.6,98C220.275,230.659,232.875,205.459,216.075,197.059z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          height: 10px;
          width: 10px;
          display: inline-block;
          margin-right: 5px;
          margin-top: 2px;
        }

        @include mobile {
          display: none;
        }

        @include mobileLandscape {
          display: none;
        }

        @include tablet {
          display: none;
        }

        @include laptop {
          display: flex;
          font-size: 16px;
          width: 100px;
          height: 40px;
          top: auto;
          left: 80px;
        }

        @include desktop {
          display: flex;
          font-size: 18px;
          height: 61px;
          width: 155px;
          top: -20px;
          left: 0;
        }
      }
    }

    @include laptop {
      margin-bottom: 25px;
    }

    @include desktop {
      margin-bottom: 30px;
    }
  }

  .purple-heading {
    text-align: center;
    margin-bottom: 20px;
  }

  .discount-text {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .overview-items {
    margin-bottom: 40px;
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;

    .overview-price {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-end;
      .overview-price__text {
        display: flex;
      }

      .title {
        margin-right: 10px;
      }
      .text {
        color: var(--color-mid-charcoal);
        margin-left: auto;
        white-space: nowrap;
        font-weight: 700;
        font-size: 20px;
      }
      .green-text {
        margin-top: 15px;
        color: var(--color-mint-green);
      }
    }

    @include mobile {
      margin-top: 35px;
      max-width: 284px;
    }

    @include mobileLandscape {
      margin-top: 13px;
      max-width: 97%;
    }

    @include tablet {
      margin-top: 17px;
      max-width: 431px;
    }
    @include laptop {
      margin-top: 50px;
      max-width: 530px;
    }

    @include desktop {
      margin-top: 42px;
      max-width: 530px;
    }

    .overview-item {
      margin-bottom: 21px;
      .product {
        font-weight: 700;
        display: grid;
        grid-template-columns: auto min-content;
        color: var(--color-mid-charcoal);
        font-size: 20px;

        .price {
          color: var(--color-mid-charcoal);
          margin-left: auto;
          white-space: nowrap;
        }
      }

      .variant {
        color: var(--color-mid-charcoal);
        font-size: 16px;
        font-weight: 300;
      }
    }
  }

  .weekly-totals {
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;
    @include mobile {
      max-width: 284px;
    }

    @include mobileLandscape {
      max-width: 97%;
    }

    @include tablet {
      max-width: 431px;
    }

    @include laptop {
      max-width: 530px;
    }

    @include desktop {
      max-width: 530px;
    }

    h2 {
      font-size: 35px;
      font-weight: 700;
      text-align: center;
      color: var(--color-raspberry-sorbet);
      margin-bottom: 24px;
      @include mobile {
        font-size: 15px;
      }
      @include mobileLandscape {
        font-size: 15px;
      }

      @include tablet {
        font-size: 15px;
      }

      @include laptop {
        font-size: 30px;
      }

      @include desktop {
        font-size: 35px;
      }
    }

    .weeks {
      .week {
        display: grid;
        grid-template-columns: auto min-content;
        margin-top: 8px;

        p {
          white-space: nowrap;
          font-size: 20px;
          font-weight: 700;
          text-align: left;
          color: var(--color-mid-charcoal);
        }
      }
    }
  }

  .tsandcs {
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;
    @include mobile {
      max-width: 284px;
    }
    @include mobileLandscape {
      max-width: 97%;
    }

    @include tablet {
      max-width: 431px;
    }

    @include laptop {
      max-width: 530px;
    }

    @include desktop {
      max-width: 530px;
    }

    color: rgb(73, 73, 73);

    button {
      height: 61px;
      width: 100%;
      margin-top: 35px;
    }

    textarea {
      box-sizing: border-box;
      padding: 15px;
    }
  }

  .one-time {
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;

    h2 {
      font-size: 21px;
      font-weight: 700;
      text-align: center;
      color: var(--color-raspberry-sorbet);
      margin-bottom: 15px;
    }

    .boxes {
      .box {
        display: grid;
        grid-template-columns: auto min-content;
        margin-top: 8px;

        p {
          white-space: nowrap;
          font-size: 20px;
          font-weight: 700;
          text-align: left;
          color: var(--color-mid-charcoal);
        }
      }

      margin-bottom: 72px;
    }
  }
}

.payment-info {
  font-size: 14px;
  font-weight: 300;
  color: var(--color-mid-charcoal);
  // margin-top: 74px;
  margin-bottom: 15px;

  max-width: 530px;
  margin-left: auto;
  margin-right: auto;

  &.bottomSpace {
    margin-bottom: 90px;
  }
  @include mobile {
    max-width: 284px;
  }
  @include mobileLandscape {
    max-width: 321px;
  }

  @include tablet {
    max-width: 431px;
  }

  @include laptop {
    max-width: 530px;
  }

  @include desktop {
    max-width: 530px;
  }
}

.frequency__radio-btn {
  margin-left: 10px;
  @include laptop {
    margin-top: 10px;
    margin-left: 0;
  }
  label {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: -0.03em;
    text-align: left;
    color: var(--color-mid-charcoal);
    width: 100%;
    position: relative;
    min-height: 22px;

    input[type="checkbox"] {
      opacity: 0;
      text-indent: -9999px;
      position: absolute;
      left: 0;

      & + span {
        position: relative;
        display: block;
        padding-left: 30px;
        line-height: 20px;

        &::before {
          position: absolute;
          content: "";
          width: 20px;
          border: 2px solid #c3cbcd;
          left: 0px;
          height: 20px;
          box-sizing: border-box;
          top: 0;
        }
      }

      &:checked {
        & + span {
          &::before {
            background-image: url("../../assets/product-detailed/tick-white.svg");
            background-color: #64cf0a;
            border-color: #64cf0a;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }
}

.quick {
  &-large {
    display: none !important;
    @include laptop {
      display: unset !important;
    }
  }

  &-small {
    display: unset !important;
    @include laptop {
      display: none !important;
    }
  }
}

.payment-icons-container {
  max-width: 530px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  @include mobile {
    max-width: 284px;
  }

  @include mobileLandscape {
    max-width: 97%;
  }

  @include tablet {
    max-width: 431px;
  }
  @include laptop {
    max-width: 530px;
  }

  @include desktop {
    max-width: 530px;
  }
}

.no-outline {
  box-shadow: unset !important;
}

.remove-btn {
  color: var(--color-raspberry-sorbet);
  text-decoration: underline;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: start;
}

.payment__buttons {
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  flex-direction: row;
  max-width: 530px;
  margin: 0 auto;
  @include mobile {
    max-width: 284px;
    flex-direction: column-reverse;
  }

  @include mobileLandscape {
    max-width: 97%;
    flex-direction: row;
  }

  @include tablet {
    max-width: 431px;
  }
  @include laptop {
    max-width: 530px;
    margin: 0 auto 30px;
  }

  @include desktop {
    max-width: 530px;
  }
  > div {
    cursor: pointer;
    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
    @include tablet {
      margin: 0;
    }
    @include laptop {
      display: unset;
      padding: 20px 30px;
    }
  }
  .next {
    background-color: var(--color-mint-green);
    color: #fff;
    font-size: 18px;
    min-width: 300px;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 40px;
  }

  .back {
    border: 1.5px solid var(--color-mint-green);
    color: var(--color-mint-green);
    background: transparent;
    font-size: 18px;
  }
}
.button-start-shopping {
  background-color: var(--color-raspberry-sorbet);
  border: none;
  border-radius: none;
  color: #ffffff;
  height: 55px;
  line-height: 55px;
  padding: 0 28px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  margin-right: 8px;
  white-space: nowrap;
  margin-top: 50px;
  @include tablet {
    height: 48px;
    line-height: 48px;
  }
  @include laptop {
    height: 55px;
    line-height: 55px;
    margin-right: 8px;
    padding: 0 38px;
  }
}
