@import '../../scss/mixins';

.add-card-to-profile {
    max-width: 450px;

    @include mobile {
        display: flex;
        flex-direction: column;
        max-width: 300px;
        margin: auto;
        margin-bottom: 16px;
        margin-top: 16px;
    }

    gap: 15px;

    p {
        color: var(--color-raspberry-sorbet);
        text-align: center;
    }

    .StripeElement {
        margin-bottom: 8px;
        border: 1px solid var(--color-mid-charcoal);
        border-radius: 8px;
        padding: 6px;
    }

    > div {
        display: flex;
        flex-direction: column;

        gap: 15px;

        // justify-content: center;
        // align-items: center;
    }

    > .right {

        > div {
            display: flex;
            flex-direction: column;
    
            gap: 5px;
            > button {
                width: 100%;
            }
        }
    }

}
