@import '../../scss/mixins';

.shop-product-list-item {
  background-color: #e2e2e2;
  transition: all 50ms ease-in;
  border-bottom: 2px solid var(--color-mint-green);
  &.active {
    .shop-product-list-item-get-it {
      color: #ffffff;
    }
    &:hover {
      .shop-product-list-item-get-it {
        color: #ffffff !important;
      }
    }
  }
  @include mobile {
    flex: 0 0 49%;
    margin-bottom: 18px;
  }
  @include mobileLandscape {
    flex: 0 0 49%;
  }
  @include tablet {
    flex: 0 0 49%;
  }
  @include laptop {
    width: 299px;
    margin-bottom: 20px;
    // opacity: .5;

    &.active {
      background-color: var(--color-mint-green);
      opacity: 1;

      .shop-product-list-item-right-top {
        p:first-of-type,
        p:last-of-type,
        p.shop-product-list-item-description {
          color: #000000;
        }
      }

      &:hover,
      &:active {
        background-color: var(--color-mint-green);
        opacity: 1;
        .shop-product-list-item-get-it {
          color: #ffffff
        }
      }
    }
  }
  @include desktop {
    width: 446px;

    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .quick-delivery-icon {
    background-image: url('../../assets/product-detailed/quick-delivery.svg');
    background-position: center;
    background-repeat: no-repeat;
    width: 42px;
    height: 48px;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  &:hover {
    background-color: #bfbfbf;
    opacity: 1;
    .shop-product-list-item-right-top {
      p:first-of-type,
      p:last-of-type,
      p.shop-product-list-item-description {
        color: #000000;
      }
      .shop-product-list-item-get-it {
        color: #567ade
      }
    }
  }

  &:active {
    background-color: #8c8c8c;
    opacity: 1;
    .shop-product-list-item-right-top {
      p:first-of-type,
      p:last-of-type,
      p.shop-product-list-item-description {
        color: #000000;
      }
      .shop-product-list-item-get-it {
        color: #567ade
      }
    }
  }

  &-left {
    position: relative;
    img {
      object-fit: cover;
      vertical-align: middle;
      @include mobile {
        width: 100%;
        height: 160px;
      }
      @include mobileLandscape {
        width: 100%;
        height: 130px;
      }
      @include tablet {
        width: 100%;
        height: 124px;
      }
      @include laptop {
        width: 100%;
        height: 105px;
      }

      @include desktop {
        width: 100%;
        height: 170px;
      }
    }
  }

  &-right {
    @include mobile {
      padding: 0 8px 8px;
    }
    // @include tablet {
    //   height: 85px;
    // }
    @include laptop {
      height: auto;
      display: flex;
      max-width: 100%;
      flex-direction: column;
      justify-content: space-between;
    }

    @include desktop {
      height: 170px;
      width: 223px;
      padding: 0 17px;
    }
    display: grid;
    grid-template-rows: auto min-content;

    &-top {
      margin: 10px 0 0;

      @include mobileLandscape {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      @include tablet {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      @include laptop {
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;
      }

      @include desktop {
        padding-top: 10px;
        padding-bottom: 15px;
      }

      p.shop-product-list-item-description {
        color: var(--color-mid-charcoal);
      }

      p:first-of-type,
      p:last-of-type {
        font-size: 15px;
        font-weight: 700;
        color: var(--color-mid-charcoal);
        line-height: 19px;
        letter-spacing: -0.03em;
      }

      p:first-of-type {
        @include laptop {
          font-size: 22px;
          line-height: normal;
          margin-bottom: 5px;
        }
        @include desktop {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 6px;
        }
      }

      p:last-of-type {
        margin-top: auto;
        padding-top: 5px;
      }

      p {
        font-size: 12px;

        @include tablet() {
          font-size: 14px;
        }
      }
    }

    &-bottom {
      color: black;
      max-width: 100%;
      .mySelectDefault {
        margin-right: 0;
        max-width: 100%;

        .fieldset {
          border: 2px solid #8ddc73;
          padding: 0;
          box-sizing: border-box;

          @include mobile {
            font-size: 15px;
          }

          @include laptop {
            font-size: 20px;
          }

          @include desktop {
            margin-top: 7px;
            margin-bottom: 7px;
            max-width: 100%;
            font-size: 20px;
            margin-bottom: 14px;
            margin-right: 0;
          }

          .select_option {
            @include mobile {
              padding: 0.8em 0.5em;
            }
            @include tablet {
              padding: 0.5em 0 0.5em 0.5em;
            }
          }
        }

        .mainSectionWrapper {
          min-height: 44px;
          cursor: pointer;

          .cancelContainer {
            display: none;
          }

          .downArrowContainer {
            .downArrowContent {
              & > svg {
                fill: var(--color-mint-green);
                border: 2px solid var(--color-mint-green);
                border-radius: 50%;
                box-sizing: border-box;
              }
            }
          }
        }

        &:not(.valueSelected) {
          &:before {
            pointer-events: none;
            position: absolute;
            z-index: 9;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            left: 15px;
            content: 'Size';
            font-size: 18px;
            font-weight: bold;
            line-height: 35px;
            letter-spacing: -0.03em;
            color: var(--color-mint-green);
          }
        }
      }
    }
  }

  &-get-it {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-cornflower-blue);
    padding: 2px 0;

    @include laptop {
      padding-bottom: 10px;
    }
  }
}
